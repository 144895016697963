import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { generateUrl } from "./utils/generateUrl"
import { defaultInstallUrl } from "./utils/installUrl"
import CompareFeatures from "./CompareFeatures"
import stars from "../images/star.svg"
import icon from "../images/icon.svg"
import Carousel2 from "./Carousel2"
import Description from "./Description"
import GMLogo from "../images/logo.png"
import ConfigContext from "./utils/configContext"

const Data = graphql`
  {
    partners: file(relativePath: { eq: "payment partners.png" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    allGhostPost(
      sort: { order: ASC, fields: published_at }
      filter: {
        tags: { elemMatch: { slug: { in: "hash-adwords_comparison" } } }
      }
    ) {
      edges {
        node {
          slug
          title
          html
        }
      }
    }
  }
`
class Contents extends React.Component {
  static contextType = ConfigContext

  state = {
    featuresVisible: false,
    installUrl: defaultInstallUrl,
    compName: "",
    bannerOrder: "",
  }

  componentDidMount() {
    // generate onelink url
    this.setState({
      installUrl: generateUrl(this.context.installUrl, this.context.forceUse),
    })
    // check for utm_comp in url
    const siteurl = new URL(window.location.href)
    const checkComp = siteurl.searchParams.get("utm_comp")
    if (checkComp) {
      this.setState({ compName: checkComp })
    }
    const getBannerOrder = siteurl.searchParams.get("ad_order")
    if (getBannerOrder) {
      this.setState({ bannerOrder: getBannerOrder })
    }
  }

  downloadApp = url => {
    const a = document.createElement("a")
    a.href = url
    a.download = url.split("/").pop()
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  nonChromeFunc = () => {
    this.props.toggleModal()
    setTimeout(() => {
      this.downloadApp(this.props.installUrl)
    }, 1100)
  }

  onClickHandler = eventSource => {
    this.props.fbEventAndShowModal(eventSource)
    this.props.isChrome ? this.props.togglePrompt() : this.nonChromeFunc()
  }

  render() {
    let {
      installUrl,
      featuresVisible,
      compName,
      competitorName,
      megaName,
      content,
      competitorImage,
      megaImage,
      hideToggleButton = false,
    } = this.state
    const Stats = (
      <div className="stats">
        <div className="rating">
          <div className="rating-num font-header align-row-center marBot5 fontGotham">
            {this.context.ratingCount || "4.8"}
          </div>
          <div className="rating-star">
            <img src={stars} alt=" " />
          </div>
          <div className="rating-label marneg5 font-text align-row-center fontGotham">
            Ratings
          </div>
        </div>
        <div className="players">
          <div className="players-num font-header marBot5 align-row-center fontGotham">
            {this.context.totalPlayerCount || "3.5"}
          </div>
          <div className="players-lakh font-text align-row-center height16 fontGotham">
            {this.context.totalPlayerCountIn || "Lakh"}
          </div>
          <div className="players-players font-text mar fontGotham">
            Players
          </div>
        </div>
        <div className="prizes">
          <div className="prizes-numb font-header marBot5 align-row-center fontGotham">
            {this.context.totalPrizeCount || "20"}
          </div>
          <div className="prizes-text1 font-text height16 fontGotham">
            Lakh+ Prizes
          </div>
          <div className="prizes-text2 font-text mar1 align-row-center fontGotham">
            Distributed
          </div>
        </div>
      </div>
    )

    const DownloadButton = (
      <div id="glossy-button" className="download">
        <button
          onClick={() => {
            this.onClickHandler("Download Button")
          }}
          type="button"
        >
          <div className="icon-flex">
            <img src={icon} alt="" />
          </div>
          <div className="download-text font-download fontGotham">
            {this.context.downloadButtonText || "Download Now"}
          </div>
        </button>

        <i />
      </div>
    )

    return (
      <StaticQuery
        query={Data}
        render={data => {
          if (compName) {
            const getAllPosts = data.allGhostPost.edges
            const currentPost = getAllPosts.filter(
              item => item.node.slug === compName
            )
            if (typeof currentPost !== "undefined" && currentPost.length > 0) {
              const getTable = currentPost.map(function (i) {
                return i.node.html
              })
              competitorName = currentPost.map(function (i) {
                return i.node.title
              })
              megaName = "GetMega"

              const tableContent = document.createElement("div")

              tableContent.innerHTML = getTable[0]

              const table = tableContent?.querySelectorAll("table")[0]

              if (table) {
                // setting content
                const imageTds = table.getElementsByTagName("td")
                const compImage = imageTds[1]
                const getmegaImage = imageTds[2]
                if (compImage) {
                  competitorImage = compImage.lastChild.src
                }
                if (getmegaImage) {
                  megaImage = getmegaImage.lastChild.src
                }

                const rows = table.getElementsByTagName("tr")
                if (rows.length < 6) {
                  hideToggleButton = true
                }
                content = []

                for (let i = 2; i < rows.length; i += 1) {
                  const tds = rows[i].getElementsByTagName("td")
                  content.push({
                    id: i,
                    category: tds[0].textContent,
                    competitorText: tds[1].innerHTML,
                    megaText: tds[2].innerHTML,
                  })
                }
              }
            }
          }

          return (
            <div className="app-container">
              <div className="main-screen">
                <div className="logo">
                  <img src={GMLogo} alt="getmega logo" />
                </div>
                <div className="image-container">
                  <Carousel2 bannerOrder={this.state.bannerOrder} />
                </div>
                {this.state.compName && content ? (
                  <CompareFeatures
                    hideToggleButton={hideToggleButton}
                    competitorImage={competitorImage}
                    competitorName={competitorName}
                    megaImage={megaImage}
                    megaName={megaName}
                    content={content}
                  />
                ) : null}

                <div className="legend">
                  {Stats}
                  {DownloadButton}
                </div>
              </div>
              <Description
                data={data}
                fbEventAndShowModal={this.props.fbEventAndShowModal}
                togglePrompt={this.props.togglePrompt}
                toggleModal={this.props.toggleModal}
                reviewsVisible={featuresVisible}
                installUrl={installUrl}
                isChrome={this.props.isChrome}
              />
            </div>
          )
        }}
      />
    )
  }
}

export default Contents
