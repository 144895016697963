/*
 Add pages in this object
 All carousel images should go into the respective folder in the Drive
 Add custom static reviews in src/static-reviews/staticReviews.js
*/
const pages = {
  cards: {
    gameName: "",
    descriptionText:
      "Play Cards games on India's most loved real money entertainment platform. 10000+ players are playing every day. Choose your contest / table, small stakes or big stakes. Unlimited Real Cash to be won!",
    descriptionList: [
      "Discover India's biggest cards tables ♠️ ❤️",
      "Play with sirf 100% verified players, no fake profiles 👩 👨",
      "Choose stakes apni marzi ke - small, medium, large, & HUGE 🏆",
      "Khelo kabhi bhi, with non-stop 24-hours tables ⏳",
      "Kisi bhi issue ke liye high-priority chat support 👨🏽‍💻",
      "Support karo 100% made in India app 🇮🇳",
    ],
    carouselImages: [
      "cards/1.jpg",
      "cards/2.jpg",
      "cards/3.jpg",
      "cards/4.jpg",
      "cards/5.jpg",
      "cards/6.jpg",
    ],
    popupImage: "poker-girl.png",
    popupTitle: "Riya won ₹89,580 first week mein!",
    popupText:
      "Play high stakes poker to win bahut jyada cash. Next tournament starts in:",
    vimeoVideoId: "460421190",
    gtagId: "AW-718511341",
    airtableName: "Poker LP Reviews",
    airtableBaseId: "appNqwhCawaU6zPpv",
    staticReviews: "cards",
    ratingCount: "4.7",
    totalPlayerCount: "3.2",
    totalPlayerCountIn: "Lakh",
    totalPrizeCount: "20",
    downloadButtonText: "Download App",
    disclaimerText:
      "This game involves an element of financial risk and may be addictive. Please play responsibly and at your own risk.",
    variants: {
      default: {
        installUrl: "https://getmega.com/latest",
        forceUse: true,
        hostingSite: "download-affiliate-cards",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
        carouselImages: [
          "cards-black/1.png",
          "cards-black/2.png",
          "cards-black/3.jpg",
          "cards-black/4.jpg",
          "cards-black/5.png",
          "cards-black/6.jpg",
        ],
      },
    },
  },
  rummy: {
    gameName: "",
    descriptionText:
      "Play Rummy on India's most loved real money entertainment platform. 10,000+ players are playing every day. Choose your contest / table, small stakes or big stakes. Unlimited Real Cash to be won!",
    descriptionList: [
      "Discover India's biggest Rummy tables ♠️ ❤️",
      "Play with sirf 100% verified players, no fake profiles 👩 👨",
      "Choose stakes apni marzi ke - small, medium, large, & HUGE 🏆",
      "Khelo kabhi bhi, with non-stop 24-hours tables ⏳",
      "Kisi bhi issue ke liye high-priority chat support 👨🏽‍💻",
      "Support karo 100% made in India app 🇮🇳",
    ],
    carouselImages: [
      "rummy/1.png",
      "rummy/2.png",
      "rummy/3.png",
      "rummy/4.png",
      "rummy/5.png",
      "rummy/6.png",
    ],
    popupImage: "rummy-girl.png",
    popupTitle: "Riya won ₹89,580 first week mein!",
    popupText:
      "Play rummy on BIG tables to win loads of cash. Next tournament starts in:",
    vimeoVideoId: "460421190",
    gtagId: "AW-718511341",
    airtableName: "Poker LP Reviews",
    airtableBaseId: "appzs5lZNAcpHlhyA",
    staticReviews: "rummy",
    ratingCount: "4.7",
    totalPlayerCount: "3.2",
    totalPlayerCountIn: "Lakh",
    totalPrizeCount: "20",
    downloadButtonText: "Download App",
    disclaimerText:
      "This game involves an element of financial risk and may be addictive. Please play responsibly and at your own risk.",
    variants: {
      default: {
        installUrl: "https://getmega.com/latest",
        forceUse: true,
        hostingSite: "download-app8",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
    },
  },
  carrom: {
    gameName: "",
    descriptionText:
      "Are you bored & alone? Do you miss playing with your friends? Don't worry, GetMega Carrom is here! For the first time in the world, play Carrom on videochat & chill with your friends, in the comfort of your home. Create your private table, play live games, and hang out with your friends on videochat! 5,00,000+ players currently live on India's only social real money entertainment platform! Don't wait, videochat with your friends now!",
    descriptionList: [
      "100% safe, secure & private Carrom games for you & your friends.",
      "Talk, watch, laugh and play Carrom together on videochat in your private rooms!",
      "24-hour chat support, priority treatment for Carrom Players.",
      "Smooth experience, awesome gameplay & non-stop winnings.",
      "Seamless withdrawal of winnings to Paytm, UPI, bank account, etc.",
      "Invite friends & win real cash when they join!",
    ],
    carouselImages: [
      "carrom-videochat/1.png",
      "carrom-videochat/2.png",
      "carrom-videochat/3.png",
      "carrom-videochat/4.png",
      "carrom-videochat/5.png",
      "carrom-videochat/6.png",
      "carrom-videochat/7.png",
    ],
    popupImage: "carrom-girl.png",
    popupTitle: "Your friends are waiting, invite them now.",
    popupText:
      "Mat karo wait, call your friends & ho jao set. Make your Carrom room in:",
    vimeoVideoId: "537657601",
    gtagId: "AW-718511341",
    airtableName: "Poker LP Reviews",
    airtableBaseId: "appwRfYrq4MiRzouD",
    staticReviews: "carrom",
    ratingCount: "4.7",
    totalPlayerCount: "3.2",
    totalPlayerCountIn: "Lakh",
    totalPrizeCount: "20",
    downloadButtonText: "Download App",
    variants: {
      default: {
        installUrl:
          "https://affiliate_asiasouth1.getmegacdn.com/latest-carrom-new",
        forceUse: true,
        hostingSite: "download-affiliate-carrom",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
    },
  },
}

module.exports = pages
