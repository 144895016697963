import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import CloseButton from "../images/close.svg"

import "../styles/Popup.css"

const Images = graphql`
  {
    image1: file(relativePath: { eq: "popup/rummy-girl.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

class Popup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      minutes: "01",
      seconds: "30",
      downloadsCount: 3598,
    }
  }

  componentDidMount() {
    // start timer for 1.5minutes
    this.startTimer(60 * 1.5)
  }

  componentWillUnmount() {
    clearInterval(this.state.countdownTimer)
  }

  startTimer = duration => {
    let timer = duration,
      minutes,
      seconds

    const countdownTimer = setInterval(() => {
      minutes = parseInt(timer / 60, 10)
      seconds = parseInt(timer % 60, 10)

      this.setState({
        minutes: minutes < 10 ? "0" + minutes : minutes,
        seconds: seconds < 10 ? "0" + seconds : seconds,
        downloadsCount:
          this.state.downloadsCount + (seconds % 10 === 0 ? 1 : 0),
      })

      if (--timer < 0) {
        this.props.closeFreeGemsPopup()
      }
    }, 1000)
    this.setState({ countdownTimer })
  }

  downloadApp = url => {
    const a = document.createElement("a")
    a.href = url
    a.download = url.split("/").pop()
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  nonChromeFunc = () => {
    this.props.toggleModal()
    setTimeout(() => {
      this.downloadApp(this.props.installUrl)
    }, 1100)
  }

  onClickHandler = eventSource => {
    this.props.closeFreeGemsPopup()
    this.props.fbEventAndShowModal(eventSource)
    this.props.isChrome ? this.props.togglePrompt() : this.nonChromeFunc()
  }

  render() {
    const { closeFreeGemsPopup } = this.props
    const { minutes, seconds, downloadsCount } = this.state

    return (
      <div id="backdrop">
        <div className="bg">
          <div id="popup">
            <div className="close">
              <div
                onClick={closeFreeGemsPopup}
                onKeyDown={closeFreeGemsPopup}
                role="button"
                tabIndex="0"
              >
                <img
                  src={CloseButton}
                  alt="close button"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <span
              style={{
                fontSize: "15px",
                textAlign: "center",
              }}
            >
              Riya won ₹89,580 1<sup>st</sup> week mein!
            </span>
            <span style={{}} className="subtext">
              Play rummy on BIG tables to win loads of cash. Next tournament
              starts in:
            </span>
            <div className="timer">
              <span>00:</span>
              {minutes}
              <span>:</span>
              {seconds}
            </div>
            <StaticQuery
              query={Images}
              render={data => {
                let fluidImgFromQuery = []
                for (let key in data) {
                  fluidImgFromQuery.push(
                    data[key].childImageSharp.gatsbyImageData
                  )
                }

                return (
                  <div
                    style={{
                      width: "212px",
                    }}
                  >
                    <div>
                      <GatsbyImage
                        image={fluidImgFromQuery[0]}
                        alt="Play Rummy"
                      />
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          this.onClickHandler("Popup Install Button")
                        }}
                        className="mobile-link"
                        type="button"
                      >
                        Install Now
                      </button>
                    </div>
                  </div>
                )
              }}
            />
            <span
              style={{
                marginTop: "16px",
                fontFamily: '"Gotham Rounded Book" !important',
              }}
              className="downloads"
            >
              Today's downloads : {downloadsCount}
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default Popup
